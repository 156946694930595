import { all, call, put, takeLatest } from 'redux-saga/effects';
import { COLLECTIONS_ACTION } from 'redux/slices/collections/types';
import assetService from '@networking/service/asset/assetService';
import { PayloadAction } from '@reduxjs/toolkit';
import { IListNftResponse } from '@networking/service/asset/types';
import { TBaseServerDataResponse } from '@networking/types';
import { collectionsActions } from 'redux/slices/collections';

const { getCollectionsNFTSuccess } = collectionsActions;

function* watchGetCollectionsNFT(action: PayloadAction<any>): any {
  try {
    const params = {
      ...action.payload,
    };
    const response: TBaseServerDataResponse<IListNftResponse> = yield call(
      assetService.getMyAsset,
      params,
    );
    // console.log(response, 'response');
    yield put(
      getCollectionsNFTSuccess({
        collectionsNFT: response.data,
        isCollectionLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(COLLECTIONS_ACTION.GET_COLLECTIONS, watchGetCollectionsNFT)]);
}

export default fetchWatcher;
