function getApiPort(url: string) {
  if (url) {
  }

  return {
    version: '',
  };
}

function getRequestUrl(url: string): string {
  const { version } = getApiPort(url);
  const baseUrlWithVersion = version ? version : '';
  return `${baseUrlWithVersion}/${url}`;
}

export default getRequestUrl;
