import { AxiosRequestConfig } from 'axios';
import { ApiResponse, RequestTransform } from 'apisauce';
import SecureHelper from '@core/helpers/SecureHelper';
import { TBaseServerDataResponse } from '@networking/types';

export const transformRequest: RequestTransform = (request: AxiosRequestConfig) => {
  if (request.headers && !request.headers.isCheckAuth) {
    delete request.headers.Authorization;
  }

  if (request.headers?.isEncrypt && request.data) {
    const encryptData = SecureHelper.encryptWithServerAuth(JSON.stringify(request.data));

    request.data = {
      encryptData,
    };
  }
};

export const transformError = (response: ApiResponse<TBaseServerDataResponse<any>>) => {
  if (response.ok && response.data?.encrypt) {
    response.data.data = JSON.parse(
      SecureHelper.decryptWithServerAuth(response.data.data),
    );
  }
};
