import { all, fork } from 'redux-saga/effects';
import marketSaga from './marketSaga';
import ssoSaga from './ssoSaga';
import collectionsSaga from './collectionsSaga';
import characterSaga from './characterSaga';
import catalogSaga from './catalogSaga';
import gamesSaga from './gamesSaga';
import currencySaga from './currencySaga';
import withListSaga from './withListSaga';
import balancesSaga from './balancesSaga';
import notificationSaga from './notificationSaga';

export default function* rootSaga() {
  yield all(
    [
      marketSaga,
      ssoSaga,
      collectionsSaga,
      characterSaga,
      catalogSaga,
      gamesSaga,
      currencySaga,
      withListSaga,
      balancesSaga,
      notificationSaga,
    ].map(fork),
  );
}
