import AppCms from '@networking/clients/AppCms';
import listUrl from '@networking/url/listUrl';
import { ItemBanner, ListBannerParams } from './types';

export default {
  // getListBanner: (params: ListBannerParams) => {
  //   return new Promise((resolve, reject) => {
  //     AppCms.get({
  //       url: listUrl.BANNER.get_all_banner,
  //       params,
  //     })
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         console.log(err, 'err');
  //         reject(err);
  //       });
  //   });
  // },

  getListBanner: () => {
    const condention = btoa(JSON.stringify({ deviceType: 'WEB' }));
    const params = {
      bannerType: 'MARKETPLACE_GAME',
      // condention,
    };
    return AppCms.get<ItemBanner>({
      url: listUrl.BANNER.get_all_banner,
      params,
    });
  },

  getListScreenShotVideo: () => {
    const condention = btoa(JSON.stringify({ deviceType: 'WEB' }));
    const params = {
      bannerType: 'SCREENSHOT_VIDEO_GAME',
      condention,
    };
    return AppCms.get<ItemBanner>({
      url: listUrl.BANNER.get_all_banner,
      params,
    });
  },
};
