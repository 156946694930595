import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INotificationState {
  notification: any;
  countRead: any;
  isLoadding: boolean;
}
const initialState: INotificationState = {
  notification: null,
  countRead: 0,
  isLoadding: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotification: (state: INotificationState, action: any) => {
      state.isLoadding = true;
    },
    getNotificationSuccess: (
      state: INotificationState,
      action: PayloadAction<INotificationState>,
    ) => {
      state.isLoadding = false;
      state.notification = { ...action.payload.notification };
      state.countRead = { ...action.payload.countRead };
    },
  },
});

// Action creators are generated for each case reducer function
export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
