import AppCms from '@networking/clients/AppCms';
import LoyaltyClient from '@networking/clients/LoyaltyClient';
import WalletClient from '@networking/clients/WalletClient';
import listUrl from '@networking/url/listUrl';
import {
  IGameCharactersParams,
  IGameCharactersRevice,
  IpackageConfigsParams,
  IpackageConfigsRevice,
  ITopUpHistoryRevice,
  IUseGiftCode,
} from './types';

export default {
  getGameCharacters: (params: IGameCharactersParams) => {
    return AppCms.get<IGameCharactersRevice>({
      url: listUrl.GAME_CHARACTER.get_game_characters,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getPackageTopUpConfigs: (params: IpackageConfigsParams) => {
    return AppCms.get<IpackageConfigsRevice>({
      url: listUrl.PACKAGE_CONFIG.package_configs,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getGameCharacter: (id: string) => {
    return AppCms.get<IGameCharactersRevice>({
      url: `${listUrl.GAME_CHARACTER.get_game_characters}/${id}`,
      config: {
        isCheckAuth: true,
      },
    });
  },

  useGiftCode: (params: IUseGiftCode) => {
    return LoyaltyClient.put<IGameCharactersRevice>({
      url: `${listUrl.GAME_CHARACTER.gift_code}`,
      params,
      config: {
        isCheckAuth: true,
        isKeepRequest: true,
        isHandleError: true,
      },
    });
  },

  getMyTopUpHistory: (params: IpackageConfigsParams) => {
    return WalletClient.get<ITopUpHistoryRevice>({
      url: listUrl.PACKAGE_CONFIG.my_top_up_histories,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },
};
