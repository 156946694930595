import Air from '@core/assets/images/properties/infinity-war/class/Air.png';
import Army from '@core/assets/images/properties/infinity-war/class/Army.png';
import Navy from '@core/assets/images/properties/infinity-war/class/Navy.png';

import Agile from '@core/assets/images/properties/immortal-warriors/class/Agile.png';
import Intelligence from '@core/assets/images/properties/immortal-warriors/class/Intelligence.png';
import Star from '@core/assets/images/properties/immortal-warriors/class/Star.png';
import Strength from '@core/assets/images/properties/immortal-warriors/class/Strength.png';

import Earth from '@core/assets/images/properties/infinity-war/element/earth.png';
import Fire from '@core/assets/images/properties/infinity-war/element/Fire.png';
import Thunder from '@core/assets/images/properties/infinity-war/element/flash.png';
import Leaf from '@core/assets/images/properties/infinity-war/element/leaf.png';
import Water from '@core/assets/images/properties/infinity-war/element/water.png';

import Humanity from '@core/assets/images/properties/immortal-warriors/race/Alliance.png';
import Forest from '@core/assets/images/properties/immortal-warriors/race/Elf.png';
import Order from '@core/assets/images/properties/immortal-warriors/race/Heaven.png';
import Chaos from '@core/assets/images/properties/immortal-warriors/race/Hell.png';
import Burning from '@core/assets/images/properties/immortal-warriors/race/Horde.png';
import Shadow from '@core/assets/images/properties/immortal-warriors/race/Undead.png';
import Humans from '@core/assets/images/properties/immortal-warriors/race/Heaven.png';
import Marians from '@core/assets/images/properties/immortal-warriors/race/Hell.png';
import Sangheis from '@core/assets/images/properties/immortal-warriors/race/Horde.png';
import Krogans from '@core/assets/images/properties/immortal-warriors/race/Undead.png';

import C from '@core/assets/images/properties/realm-of-galaxy/detail/Growth.png';
import R from '@core/assets/images/properties/realm-of-galaxy/detail/Growth.png';
import SR from '@core/assets/images/properties/realm-of-galaxy/detail/Growth.png';
import SSR from '@core/assets/images/properties/realm-of-galaxy/detail/Growth.png';

import InfinityWarAtk from '@core/assets/images/properties/infinity-war/detail/atk.png';
import InfinityWarDef from '@core/assets/images/properties/infinity-war/detail/def.png';
import InfinityWarCmd from '@core/assets/images/properties/infinity-war/detail/cmd.png';
import InfinityWarStriper from '@core/assets/images/properties/infinity-war/detail/striper.png';

import ImmortalWarriorsAtk from '@core/assets/images/properties/immortal-warriors/detail/Attack.png';
import ImmortalWarriorsDef from '@core/assets/images/properties/immortal-warriors/detail/Def.png';
import ImmortalWarriorsHP from '@core/assets/images/properties/immortal-warriors/detail/HP.png';
import ImmortalWarriorsSpeed from '@core/assets/images/properties/immortal-warriors/detail/Speed.png';
import ImmortalWarriorsPower from '@core/assets/images/properties/immortal-warriors/detail/Power.png';

import SpaceAlphaAtk from '@core/assets/images/properties/space-alpha/detail/ATK.png';
import SpaceAlphaDef from '@core/assets/images/properties/space-alpha/detail/Amor.png';
import SpaceAlphaHP from '@core/assets/images/properties/space-alpha/detail/HP.png';
import SpaceAlphaAttackType from '@core/assets/images/properties/space-alpha/detail/Attack-Type.png';
import SpaceAlphaAVGVelocity from '@core/assets/images/properties/space-alpha/detail/AVG-Velocity.png';
import SpaceAlphaFuelType from '@core/assets/images/properties/space-alpha/detail/Fuel-Type.png';
import SpaceAlphaLaunchers from '@core/assets/images/properties/space-alpha/detail/Launchers.png';
import SpaceAlphaMaxVelocity from '@core/assets/images/properties/space-alpha/detail/Max-Velocity.png';
import SpaceAlphaPower from '@core/assets/images/properties/space-alpha/detail/Powergrid.png';
import SpaceAlphaRange from '@core/assets/images/properties/space-alpha/detail/range.png';
import SpaceAlphaTrustBase from '@core/assets/images/properties/space-alpha/detail/TrustBase.png';
import SpaceAlphaTurrets from '@core/assets/images/properties/space-alpha/detail/Turrets.png';

import RealmOfGalaxyGrowth from '@core/assets/images/properties/realm-of-galaxy/detail/Growth.png';
import RealmOfGalaxyMorale from '@core/assets/images/properties/realm-of-galaxy/detail/Morale.png';
import RealmOfGalaxyPhysique from '@core/assets/images/properties/realm-of-galaxy/detail/Physique.png';
import RealmOfGalaxyPower from '@core/assets/images/properties/realm-of-galaxy/detail/Power.png';
import RealmOfGalaxySupport from '@core/assets/images/properties/realm-of-galaxy/detail/Support.png';
import RealmOfGalaxyDevelop from '@core/assets/images/properties/realm-of-galaxy/detail/Develop.png';

import MyMasterWarAtk from '@core/assets/images/properties/my-master-war/icon-tan-cong.png';
import MyMasterWarDef from '@core/assets/images/properties/my-master-war/icon-phong-thu.png';
import MyMasterWarCmd from '@core/assets/images/properties/my-master-war/icon-strength.png';
import MyMasterWarHP from '@core/assets/images/properties/my-master-war/icon-hp.png';
import MyMasterWarIntelligent from '@core/assets/images/properties/my-master-war/icon-intelligent.png';
import MyMasterWarMarshal from '@core/assets/images/properties/my-master-war/icon-marshal.png';
import MyMasterWarexpert from '@core/assets/images/properties/my-master-war/Venus.png';
import Venus from '@core/assets/images/properties/my-master-war/Venus.png';
import Jupiter from '@core/assets/images/properties/my-master-war/Jupiter.png';
import Mercury from '@core/assets/images/properties/my-master-war/Mercury.png';
import Mars from '@core/assets/images/properties/my-master-war/Mars.png';
import Saturn from '@core/assets/images/properties/my-master-war/Saturn.png';

import level from '@core/assets/images/icons/level.png';
import star from '@core/assets/images/icons/star.png';

import MetaFiGoAge from '@core/assets/images/properties/metafi-go/age.png';
import MetaFiGoHeight from '@core/assets/images/properties/metafi-go/height.png';
import MetaFiGoWeight from '@core/assets/images/properties/metafi-go/weight.png';
import MetaFiGoGender from '@core/assets/images/properties/metafi-go/gender.png';

import constants from '.';

export const ICONS_CLASS: any = {
  Air,
  Army,
  Navy,
  Agile,
  Intelligence,
  Star,
  Strength,
};

export const ICONS_ELEMENT: any = {
  Earth,
  Fire,
  Thunder,
  Leaf,
  Water,
  Venus,
  Jupiter,
  Mercury,
  Mars,
  Saturn,
};

export const ICONS_RACE: any = {
  Burning,
  Forest,
  Order,
  Humanity,
  Shadow,
  Chaos,
  Humans,
  Marians,
  Sangheis,
  Krogans,
};

export const ICONS_RARITY: any = {
  C,
  R,
  SR,
  SSR,
};

// export const ICONS_ATTACK_TYPE: any = {
//   Single,
//   '3 at once': 3_at_once,
//   '5 at once': 5_at_once,
// };

export const ICONS_DETAIL: any = {
  InfinityWarAtk,
  InfinityWarDef,
  InfinityWarCmd,
  InfinityWarStriper,
  ImmortalWarriorsAtk,
  ImmortalWarriorsDef,
  ImmortalWarriorsHP,
  ImmortalWarriorsSpeed,
  ImmortalWarriorsPower,
  SpaceAlphaAtk,
  SpaceAlphaDef,
  SpaceAlphaHP,
  SpaceAlphaAttackType,
  SpaceAlphaAVGVelocity,
  SpaceAlphaFuelType,
  SpaceAlphaLaunchers,
  SpaceAlphaMaxVelocity,
  SpaceAlphaPower,
  SpaceAlphaTurrets,
  SpaceAlphaRange,
  SpaceAlphaTrustBase,
  RealmOfGalaxyGrowth,
  RealmOfGalaxyMorale,
  RealmOfGalaxyPhysique,
  RealmOfGalaxyPower,
  RealmOfGalaxyDevelop,
  RealmOfGalaxySupport,
  MyMasterWarAtk,
  MyMasterWarCmd,
  MyMasterWarDef,
  MyMasterWarHP,
  MyMasterWarIntelligent,
  MyMasterWarMarshal,
  MyMasterWarexpert,
  MetaFiGoAge,
  MetaFiGoHeight,
  MetaFiGoWeight,
  MetaFiGoGender,
};

export const DETAIL_TYPE: any = {
  heroClass: {
    name: 'Class',
    iconList: ICONS_CLASS,
    type: constants.ELEMENT_CLASS.CLASS,
  },
  element: {
    name: 'Element',
    iconList: ICONS_ELEMENT,
    type: constants.ELEMENT_CLASS.ELEMENT,
  },
  command: {
    name: 'CMD',
    iconList: ICONS_DETAIL,
  },
  level: {
    name: 'Level',
    baseIcon: level,
  },
  attack: {
    name: 'ATK',
    iconList: ICONS_DETAIL,
  },
  defend: {
    name: 'DEF',
    iconList: ICONS_DETAIL,
  },
  race: {
    name: 'Race',
    iconList: ICONS_RACE,
    type: constants.ELEMENT_CLASS.RACE,
  },
  speed: {
    name: 'Speed',
    iconList: ICONS_DETAIL,
  },
  power: {
    name: 'Power',
    iconList: ICONS_DETAIL,
  },
  growth: {
    name: 'Growth',
    iconList: ICONS_DETAIL,
  },
  morale: {
    name: 'Morale',
    iconList: ICONS_DETAIL,
  },
  physique: {
    name: 'Physique',
    iconList: ICONS_DETAIL,
  },
  support: {
    name: 'Support',
    iconList: ICONS_DETAIL,
  },
  develop: {
    name: 'Develop',
    iconList: ICONS_DETAIL,
  },
  hp: {
    name: 'HP',
    iconList: ICONS_DETAIL,
  },
  ranger: {
    name: 'Ranger',
    iconList: ICONS_DETAIL,
  },
  attackType: {
    name: 'Attack Type',
    iconList: ICONS_DETAIL,
    type: constants.ELEMENT_CLASS.ATTACK_TYPE,
  },
  avgVelocity: {
    name: 'AVG Velocity',
    iconList: ICONS_DETAIL,
  },
  trustBase: {
    name: 'Trustbase',
    iconList: ICONS_DETAIL,
  },
  rangmaxVelocityer: {
    name: 'Max Velocity',
    iconList: ICONS_DETAIL,
  },
  fuelType: {
    name: 'Fuel Type',
    iconList: ICONS_DETAIL,
    type: constants.ELEMENT_CLASS.FUEL_TYPE,
  },
  turrets: {
    name: 'Turrets',
    iconList: ICONS_DETAIL,
  },
  launchers: {
    name: 'Launchers',
    iconList: ICONS_DETAIL,
  },
  intelligent: {
    name: 'Intelligent',
    iconList: ICONS_DETAIL,
  },
  potential: {
    name: 'Marshal',
    baseIcon: MyMasterWarMarshal,
  },
  expert: {
    name: 'Expert',
    iconList: ICONS_DETAIL,
  },
  strength: {
    name: 'Strength',
    iconList: ICONS_DETAIL,
  },
  age: {
    name: 'Age',
    baseIcon: MetaFiGoAge,
  },
  weight: {
    name: 'Weight',
    baseIcon: MetaFiGoWeight,
  },
  height: {
    name: 'Height',
    baseIcon: MetaFiGoHeight,
  },
  gender: {
    name: 'Gender',
    baseIcon: MetaFiGoGender,
  },
  stripe: {
    name: 'Stripe',
    iconList: ICONS_DETAIL,
  },
  star: {
    name: 'Star',
    iconList: ICONS_DETAIL,
    baseIcon: star,
  },
};
