import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { NFT_ACTION } from 'redux/slices/market/types';
import assetService from '@networking/service/asset/assetService';
import { marketActions } from 'redux/slices/market';
import { PayloadAction } from '@reduxjs/toolkit';
import { IListNftResponse } from '@networking/service/asset/types';
import { TBaseServerDataResponse } from '@networking/types';

const { getListNFTSuccess } = marketActions;

function* watchGetListNFT(action: PayloadAction<any>): any {
  try {
    const params = {
      ...action.payload,
    };
    const response: TBaseServerDataResponse<IListNftResponse> = yield call(
      assetService.getAssetSale,
      params,
    );
    if (response.data) {
      yield put(
        getListNFTSuccess({
          dataNFT: response.data,
          isLoadding: false,
        }),
      );
    }
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(NFT_ACTION.GET_LIST_NFT, watchGetListNFT)]);
}

export default fetchWatcher;
