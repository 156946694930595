import AppCms from '@networking/clients/AppCms';
import listUrl from '@networking/url/listUrl';
import {
  GameFeedParams,
  PostsCommentParams,
  IGameReceiver,
  GameServersParams,
  IGameServersReceiver,
  IListGameServersResponse,
} from './types';

export default {
  getListGame: () => {
    return AppCms.get<IGameReceiver[]>({
      url: listUrl.GAME.get_all_game,
    });
  },

  getGameFeed: (params: GameFeedParams) => {
    return new Promise((resolve, reject) => {
      AppCms.get({
        url: listUrl.GAME_FEED.feeds,
        params: params,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  likePosts: (id: string) => {
    return new Promise((resolve, reject) => {
      AppCms.put({
        url: `${listUrl.GAME_FEED.feed}/${id}/like`,
        config: {
          isCheckAuth: true,
        },
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  sharePosts: (id: string) => {
    return new Promise((resolve, reject) => {
      AppCms.put({
        url: `${listUrl.GAME_FEED.feed}/${id}/share`,
        config: {
          isCheckAuth: true,
        },
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  commentPosts: (id: string, data: PostsCommentParams) => {
    return new Promise((resolve, reject) => {
      AppCms.post({
        url: `${listUrl.GAME_FEED.feed}/${id}/comment`,
        data,
        config: {
          isCheckAuth: true,
        },
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  likeComment: (id: string) => {
    return new Promise((resolve, reject) => {
      AppCms.post({
        url: `${listUrl.GAME_FEED.comment_like}/${id}/like`,
        config: {
          isCheckAuth: true,
        },
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  getInfoGameFeed: (id: string) => {
    return AppCms.get<IGameReceiver>({
      url: `${listUrl.GAME_FEED.list_game_info}/${id}`,
    });
  },

  getGameServer: (params: GameServersParams) => {
    return AppCms.get<IListGameServersResponse>({
      url: listUrl.GAME.game_servers,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getGameProperties: () => {
    return AppCms.get<any>({
      url: listUrl.GAME.game_properties,
    });
  },

  getComments: (id: string, params: any) => {
    return new Promise((resolve, reject) => {
      AppCms.get({
        url: `${listUrl.GAME_FEED.load_comment}/${id}/comments`,
        // params,
        // config: {
        //   isCheckAuth: true,
        // },
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },

  getForbiddenWords: () => {
    return AppCms.get<string[]>({
      url: listUrl.GAME_FEED.forbidden_words,
    });
  },
};
