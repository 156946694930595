import { all, call, put, takeLatest } from 'redux-saga/effects';
import { TBaseServerDataResponse } from '@networking/types';
import { GAMES_ACTION_TYPES } from 'redux/slices/games/type';
import gameService from '@networking/service/game/gameService';
import { IGameReceiver } from '@networking/service/game/types';
import { gamesActions } from 'redux/slices/games';

const { getListGamesSuccess } = gamesActions;

function* watchGetListGames(): any {
  try {
    const response: TBaseServerDataResponse<IGameReceiver[]> = yield call(
      gameService.getListGame,
    );
    const sortDta = response.data?.sort((a, b) => a.id - b.id);
    yield put(
      getListGamesSuccess({
        listGames: sortDta,
        isLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(GAMES_ACTION_TYPES.GET_LIST_GAMES, watchGetListGames)]);
}

export default fetchWatcher;
