class AppModalManager {
  modalRef: any = null;

  register = (ref: any) => {
    this.modalRef = ref;
  };

  unregister = (ref: any) => {
    this.modalRef = null;
  };

  showModal = (data: any) => {
    this.modalRef?.showModal(data);
  };

  hideModal = () => {
    this.modalRef?.hideModal();
  };

  toggle = () => {
    this.modalRef?.toggle();
  };
}

export default new AppModalManager();
