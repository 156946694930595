import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import themeConfig from 'configs/themeConfig';

interface ILayoutState {
  skin: () => unknown;
  isRTL: boolean;
  menuCollapsed: unknown;
  menuHidden: boolean;
  contentWidth: string;
}

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.sessionStorage.getItem('menuCollapsed');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Returns Initial Layout Skin
const initialLayoutSkin = () => {
  const item = window.sessionStorage.getItem('skin');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin;
};

const initialState: ILayoutState = {
  skin: initialLayoutSkin(),
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleContentWidth: (state: ILayoutState, action: PayloadAction<string>) => {
      state.contentWidth = action.payload;
    },
    handleMenuCollapsed: (state: ILayoutState, action: PayloadAction<any>) => {
      window.sessionStorage.setItem('menuCollapsed', action.payload);
      state.menuCollapsed = action.payload;
    },
    handleMenuHidden: (state: ILayoutState, action: PayloadAction<boolean>) => {
      state.menuHidden = action.payload;
    },
    handleRTL: (state: ILayoutState, action: PayloadAction<boolean>) => {
      state.isRTL = action.payload;
    },
    handleSkin: (state: ILayoutState, action: PayloadAction<any>) => {
      state.skin = action.payload;
    },
  },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice.reducer;
