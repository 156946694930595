import { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { Modal } from 'reactstrap';
import useModal from './useModal';

function AppModal({}: any, ref: any) {
  const { hideModal, isVisible, showModal, toggle } = useModal();
  const [data, setData] = useState<any>(<div />);

  useImperativeHandle(ref, () => ({
    showModal: (show: any) => {
      setData(show);
      showModal();
    },
    hideModal: () => {
      setData(<div />);
      hideModal();
    },
    toggle,
  }));

  return (
    <Modal
      backdrop={'static'}
      toggle={toggle}
      isOpen={isVisible}
      centered
      className='modal-lg'>
      {data}
    </Modal>
  );
}

export default forwardRef(AppModal);
