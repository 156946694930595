import { toast } from 'react-toastify';

export default {
  POSITION: {
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left',
    TOP_CENTER: 'top-center',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_CENTER: 'bottom-center',
  },

  showDefaultToast: ({
    content = 'Toast Example',
    position = 'top-right',
    duration = 3000,
  }) => {
    toast(content, {
      position: position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },

  showInfoToast: ({
    content = 'Toast Example',
    position = 'top-right',
    duration = 3000,
  }) => {
    toast.info(content, {
      position: position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },

  showErrorToast: ({
    content = 'Toast Example',
    position = 'top-right',
    duration = 3000,
  }) => {
    toast.error(content, {
      position: position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },

  showWarningToast: ({
    content = 'Toast Example',
    position = 'top-right',
    duration = 3000,
  }) => {
    toast.warn(content, {
      position: position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },

  showSuccessToast: ({
    content = 'Toast Example',
    position = 'top-right',
    duration = 1000,
  }) => {
    toast.success(content, {
      position: position,
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};
