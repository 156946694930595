import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBalancesState {
  balances: any;
  balanceLoyalty: number;
  currentVip: any;
  refQuest: any;
  notification?: any;
  isLoadding: boolean;
}
const initialState: IBalancesState = {
  balances: null,
  balanceLoyalty: 0,
  currentVip: null,
  refQuest: null,
  notification: null,
  isLoadding: false,
};

export const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    getBalances: (state: IBalancesState) => {
      state.isLoadding = true;
    },
    getBalancesSuccess: (
      state: IBalancesState,
      action: PayloadAction<IBalancesState>,
    ) => {
      state.isLoadding = false;
      state.balances = { ...action.payload.balances };
      state.balanceLoyalty = action.payload.balanceLoyalty;
      state.currentVip = { ...action.payload.currentVip };
      state.refQuest = { ...action.payload.refQuest };
      state.notification = { ...action.payload.notification };
    },
  },
});

// Action creators are generated for each case reducer function
export const balancesActions = balancesSlice.actions;

export default balancesSlice.reducer;
