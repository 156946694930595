import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGameState {
  listGames: any;
  isLoadding: boolean;
}
const initialState: IGameState = {
  listGames: [],
  isLoadding: false,
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    getListGames: (state: IGameState) => {
      state.isLoadding = true;
    },
    getListGamesSuccess: (state: IGameState, action: PayloadAction<IGameState>) => {
      state.isLoadding = false;
      state.listGames = [...action.payload.listGames];
    },
  },
});

// Action creators are generated for each case reducer function
export const gamesActions = gamesSlice.actions;

export default gamesSlice.reducer;
