import AppCms from '@networking/clients/AppCms';
import listUrl from '@networking/url/listUrl';

export default {
  getCurrencies: () => {
    return AppCms.get({
      url: listUrl.CURRENCIES.currencies,
    });
  },
};
