import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import constants from 'utility/constants';
import { getDataFromJson } from 'utility/utils';

interface IAuthState {
  userData: any;
  isLoggingIn: boolean;
  // balances: any;
  // balanceLoyalty: any;
  // currentVip: any;
}
const initialState: IAuthState = {
  userData: sessionStorage.getItem(constants.SESSION_STORAGE_KEY.UI)
    ? getDataFromJson(sessionStorage.getItem(constants.SESSION_STORAGE_KEY.UI))
    : null,
  isLoggingIn: false,
  // balances: sessionStorage.getItem(constants.SESSION_STORAGE_KEY.BALANCE),
  // balanceLoyalty: null,
  // currentVip: null,
};

export const ssoSlice = createSlice({
  name: 'sso',
  initialState,
  reducers: {
    loginSSO: (state: IAuthState, action: any) => {
      state.isLoggingIn = true;
    },
    loginSSOSuccess: (state: IAuthState, action: PayloadAction<any>) => {
      state.isLoggingIn = false;
      state.userData = { ...action.payload.userData };
      // state.balances = { ...action.payload.balances };
      // state.balanceLoyalty = { ...action.payload.balanceLoyalty };
      // state.currentVip = { ...action.payload.currentVip };
    },
    logoutSSO: () => {},
    logoutSSOSuccess: (state: IAuthState) => {
      state.userData = null;
    },
    loginSSOFail: (state: IAuthState) => {
      state.isLoggingIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const ssoActions = ssoSlice.actions;

export default ssoSlice.reducer;
