import AppCms from '@networking/clients/AppCms';
import LoyaltyClient from '@networking/clients/LoyaltyClient';
import WalletClient from '@networking/clients/WalletClient';
import listUrl from '@networking/url/listUrl';
import { ISummaryRefBonusInfoParams } from './types';

export default {
  getBalance: () => {
    return WalletClient.get({
      url: listUrl.BALANCE.balances,
    });
  },
  getBalanceLoyalty: () => {
    return LoyaltyClient.get({
      url: listUrl.LOYALTY.balance_loyalty,
    });
  },
  getCurrentVip: () => {
    return LoyaltyClient.get({
      url: listUrl.LOYALTY.current_vip,
    });
  },
  getVipConfig: () => {
    return AppCms.get({
      url: listUrl.LOYALTY.vip_config,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getRefRewardConfig: () => {
    return AppCms.get({
      url: listUrl.LOYALTY.ref_reward_config,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getRefQuest: () => {
    return LoyaltyClient.get({
      url: listUrl.LOYALTY.current_ref_quest,
    });
  },

  getSummaryRefBonusInfo: () => {
    return LoyaltyClient.get({
      url: listUrl.LOYALTY.summary_ref_bonus_info,
    });
  },

  getSummaryRefBonusInfoDetail: (params: ISummaryRefBonusInfoParams) => {
    return new Promise((resolve, reject) => {
      LoyaltyClient.get({
        url: listUrl.LOYALTY.summary_ref_bonus_info_detail,
        params,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err, 'err');
          reject(err);
        });
    });
  },
};
