import { combineReducers } from '@reduxjs/toolkit';

import navbar from 'redux/slices/navbar';
import layout from 'redux/slices/layout';
import localeReducer from 'redux/slices/locale';
import ssoReducer from 'redux/slices/sso';
import marketReducer from 'redux/slices/market';
import collectionsReducer from 'redux/slices/collections';
import characterReducer from 'redux/slices/character';
import catalogReducer from 'redux/slices/catalog';
import gamesReducer from 'redux/slices/games';
import currencyReducer from 'redux/slices/currency';
import withListReducer from 'redux/slices/withList';
import filterReducer from 'redux/slices/filter';
import balancesReducer from 'redux/slices/balances';
import notificationReducer from 'redux/slices/notification';

const rootReducer = combineReducers({
  navbar,
  layout,
  localeReducer,
  marketReducer,
  ssoReducer,
  collectionsReducer,
  characterReducer,
  catalogReducer,
  gamesReducer,
  currencyReducer,
  withListReducer,
  filterReducer,
  balancesReducer,
  notificationReducer,
});

export default rootReducer;
