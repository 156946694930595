import moment from 'moment';
import constants from 'utility/constants';
import { DETAIL_TYPE } from 'utility/constants/mapingIcon';
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

export const formatDateTime = value => {
  if (!value) return value;
  return moment(value).format('DD-MM-YYYY HH:mm:ss');
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => sessionStorage.getItem('accessToken');
export const getUserData = () => JSON.parse(sessionStorage.getItem('userData'));
export const isUserAuthLoggedIn = () =>
  sessionStorage.getItem(constants.SESSION_STORAGE_KEY.JWT);

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/market';
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#404656', // for option hover bg-color
    primary: '#404656', // for selected option bg-color
    // primary25: '#7367f01a', // for option hover bg-color
    // primary: '#7367f0', // for selected option bg-color
    // neutral10: '#7367f0', // for tags bg-color
    // neutral20: '#ededed', // for input border-color
    // neutral30: '#ededed', // for input hover border-color
  },
});

export const checkGameId = gameId => {
  const listGame = JSON.parse(
    sessionStorage.getItem(constants.SESSION_STORAGE_KEY.LIST_GAME),
  );
  if (listGame?.length) {
    if (listGame.findIndex(f => f.id == gameId) > -1) {
      return true;
    }
    return false;
  }
  return false;
};

export const FormatContractAddress = contractAddress => {
  if (!contractAddress) return;
  const f = contractAddress.slice(0, 7);
  const l = contractAddress.slice(contractAddress.length - 7, contractAddress.length);
  return `${f}...${l}`;
};

export const FormatCurrency = value => {
  const thousandSeperator = ',';
  const decimalPoint = '.';
  if (!value) {
    return `${value}`;
  }
  // if (CURRENCY_ALLOW_DECIMAL_POINT.includes(currencyUnit)) { value = parseFloat(value).toFixed(2); }
  const parts = value.toString().split(decimalPoint);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeperator);
  return `${parts.join(decimalPoint)}`;
};

export const Makeid = length => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDataFromJson = data => {
  if (data && typeof data == 'string') {
    return JSON.parse(data);
  }
  return data;
};

export const removeEmptyObject = obj => {
  return Object.keys(obj)
    .filter(k => obj[k] != null && obj[k] != '' && obj[k] != NaN)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const mappingClassElementType = (list, item, type) => {
  const baseValue = list.filter(f => f.type == type);
  let fValue = null;
  switch (type) {
    case constants.ELEMENT_CLASS.CLASS:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.heroClass,
      );
      break;
    case constants.ELEMENT_CLASS.ELEMENT:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.element,
      );
      break;
    case constants.ELEMENT_CLASS.ELEMENT_BACKGROUND_COLOR:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.element,
      );
      break;
    case constants.ELEMENT_CLASS.RACE:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.race,
      );
      break;
    case constants.ELEMENT_CLASS.RARITY:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.rarity,
      );
      break;
    case constants.ELEMENT_CLASS.ATTACK_TYPE:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.attackType,
      );
      break;
    case constants.ELEMENT_CLASS.FUEL_TYPE:
      fValue = ([...baseValue] || []).find(
        f => f?.gameId == item?.gameId && f?.elementId == item?.fuelType,
      );
      break;
  }
  if (fValue) {
    return fValue.value;
  }
  return item?.elementId;
};

export const mappingType = (list, elementId, type) => {
  const baseValue = list.filter(f => f.type == type);
  let fValue = ([...baseValue] || []).find(f => f?.elementId == elementId);
  if (fValue) {
    return fValue.value;
  }
  return elementId;
};

export const mappingGame = (listGame, gameId) => {
  const fGame = listGame.find(f => f.id == gameId);
  if (fGame) {
    return fGame;
  }
  return null;
};

export const mappingStatusNFT = assetStatus => {
  if (constants.STATUS_NFT[assetStatus]) {
    return constants.STATUS_NFT[assetStatus];
  }
  return assetStatus;
};

export const getElementByGame = (listElement, gameId) => {
  const listElementInGame = listElement.filter(
    f =>
      f.gameId == gameId && Object.keys(constants.MAPPING_CLASS_ELEMENT).includes(f.type),
  );
  const object = _.chain(listElementInGame)
    // Group the elements of Array based on `color` property
    .groupBy('type')
    .value();
  return object;
};

export const checkElmentInGame = (listElement, gameId, type) => {
  const iItem = listElement.findIndex(f => f.gameId == gameId && f.type == type);
  if (iItem > -1) {
    return true;
  }
  return false;
};

export const mappingGamePropertiesDetail = (list, gameId, value) => {
  const fValue = list.find(f => f.game.id == gameId && f.name == value);
  if (fValue) {
    return fValue.icon;
  }
  return value;
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const getItemByGameType = (list, gameId, type) => {
  const fValue = list.filter(f => f.gameId == gameId && f.type == type);

  return fValue;
};

export const mappingCurrency = (list, address) => {
  const fValue = list.find(f => f.address?.toLowerCase() == address?.toLowerCase());
  return fValue;
};

export const getPointDecimal = number => {
  if (number && number.toString().includes('.')) {
    const value = number.toString().split('.');
    return value[1].length;
  }
  return 0;
};

export const checkItemInWithList = (listItem, item) => {
  return listItem.includes(item?.assetOutput?.itemId);
};

export const getItemActiveFilter = (listFilter, type) => {
  if (listFilter?.length) {
    return listFilter?.find(f => f.type == type)?.id;
  }
  return null;
};

export const removeItemInArray = (list, item) => {
  const tmp = [...list];
  const index = list.findIndex(f => f == item);
  tmp.splice(index, 1);
  return tmp;
};

export const getObjectById = (list, id, type = 'value') => {
  if (id) {
    return list.find(f => f[type] == id);
  }
  return null;
};

export const getListProperties = list => {
  const arr = Object.keys(DETAIL_TYPE).filter(
    f => Object.keys(list).includes(f) && f != 'stripe',
  );
  if (arr?.length > 6) {
    return arr.slice(0, 6);
  }
  return arr;
};
