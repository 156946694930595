import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import notificationService from '@networking/service/notification/notificationService';
import { notificationActions } from 'redux/slices/notification';
import { NOTIFICATION_ACTION } from 'redux/slices/notification/types';

const { getNotificationSuccess } = notificationActions;

function* watchGetnotification(action: PayloadAction<any>): any {
  try {
    const data = {
      ...action.payload,
    };
    const notification = yield call(notificationService.getNotifications, data);
    const countRead = yield call(notificationService.countUnread);
    yield put(
      getNotificationSuccess({
        notification: notification.data,
        countRead: countRead,
        isLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(NOTIFICATION_ACTION.GET_NOTIFICATION, watchGetnotification)]);
}

export default fetchWatcher;
