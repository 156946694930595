import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import constants from 'utility/constants';
import { SSO_ACTION } from 'redux/slices/sso/types';
import ssoService from '@networking/service/sso/ssoService';
import toastUtils from 'utility/utils/toastUtils';
import { ssoActions } from 'redux/slices/sso';
import balanceService from '@networking/service/balance/balanceService';

const { loginSSOSuccess, logoutSSOSuccess, loginSSOFail } = ssoActions;

function* watchLoginSSO(action: any): any {
  try {
    const data = {
      ...action.payload,
      clientId: constants.USER_AUTH.CLIENT_ID,
      clientSecret: constants.USER_AUTH.CLIENT_SECRET,
    };
    const params = {
      type: constants.AUTH_TYPE.CREDENTIALS,
    };
    const response = yield call(ssoService.login, data, params);
    const userData = yield call(ssoService.getUserInfo);
    const UI = {
      ...userData.data,
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
        {
          action: 'read',
          subject: 'all',
        },
      ],
    };
    yield put(
      loginSSOSuccess({
        userData: UI,
        // balances: balancesData.data,
        // balanceLoyalty: balancesLoyalty.data,
        // currentVip: currentVip.data,
      }),
    );
    // toastUtils.showSuccessToast({ content: 'Login Success' });
    sessionStorage.setItem(
      constants.SESSION_STORAGE_KEY.JWT,
      JSON.stringify(response.data.accessToken),
    );
    sessionStorage.setItem(
      constants.SESSION_STORAGE_KEY.SK,
      JSON.stringify(response.data.sk),
    );
    sessionStorage.setItem(constants.SESSION_STORAGE_KEY.UI, JSON.stringify(UI));
    // sessionStorage.setItem(
    //   constants.SESSION_STORAGE_KEY.BALANCE,
    //   JSON.stringify(balancesData.data),
    // );
    // sessionStorage.setItem(
    //   constants.SESSION_STORAGE_KEY.BALANCE_LOYALTY,
    //   JSON.stringify(balancesLoyalty.data),
    // );
    // sessionStorage.setItem(
    //   constants.SESSION_STORAGE_KEY.CURRENT_VIP,
    //   JSON.stringify(currentVip.data),
    // );
    return;
  } catch (error) {
    console.log(error, 'error');
    yield put(loginSSOFail());
    toastUtils.showErrorToast({
      content: 'The account information is not correct, please login again',
    });
  }
}

function* watchLoginSSOSuccess(): any {
  try {
    yield select(state => state.ssoReducer.userData);
  } catch (error) {}
}

function* watchLogoutSSO() {
  try {
    yield put(logoutSSOSuccess());
  } catch (error) {}
}

function* fetchWatcher() {
  yield all([
    takeLatest(SSO_ACTION.LOGIN_SSO, watchLoginSSO),
    takeLatest(SSO_ACTION.LOGIN_SSO_SUCCESS, watchLoginSSOSuccess),
    takeLatest(SSO_ACTION.LOGOUT_SSO, watchLogoutSSO),
  ]);
}

export default fetchWatcher;
