// ** React Imports
import React, { Suspense, lazy, useRef, useEffect } from 'react';

// ** Intl, CASL & ThemeColors Context

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ** Core styles
import '@core/scss/core.scss';
import '@core/assets/scss/style.scss';
import FallbackSpinner from '@core/components/spinner/FallbackSpinner';
import AppModal from '@core/components/app-modal';
import { useAppDispatch } from 'redux/storeConfig/store';
import AppModalManager from '@core/components/app-modal/AppModalManager';
import { LoadingProvider } from 'providers/LoadingProvider';
import { catalogActions } from 'redux/slices/catalog';
import { gamesActions } from 'redux/slices/games';
import { currencyActions } from 'redux/slices/currency';
import { IntlProviderWrapper } from 'utility/context/Internationalization';
import { ThemeContext } from 'utility/context/ThemeColors';
import { AbilityContext } from 'utility/context/Can';
import ability from 'configs/acl/ability';

const LazyRouter = lazy(() => import('./router/Router'));

function App() {
  const modalRefManager = useRef(null);
  const dispatch = useAppDispatch();
  const { getCatalog } = catalogActions;
  const { getListGames } = gamesActions;
  const { getCurrency } = currencyActions;

  useEffect(() => {
    AppModalManager.register(modalRefManager.current);
    return () => {
      AppModalManager.unregister(modalRefManager.current);
    };
  }, [modalRefManager]);

  useEffect(() => {
    dispatch(getCatalog());
    dispatch(getListGames());
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    // dispatch(getCatalog());
    setTimeout(() => {
      setInterval(() => {
        dispatch(getCurrency());
      }, 30000);
    });
  }, []);

  return (
    <LoadingProvider>
      <Suspense fallback={<FallbackSpinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <LazyRouter />
              <ToastContainer
                position='top-right'
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
      <AppModal ref={modalRefManager} />
    </LoadingProvider>
  );
}

export default App;
