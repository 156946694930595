import { IElementConfig } from '@networking/service/asset/types';
import { ItemBanner } from '@networking/service/banner/types';
import { ICurrencyRevice } from '@networking/service/currency/types';
import { ISiteConfig } from '@networking/service/siteConfig/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICurrencyState {
  listCurrencies: ICurrencyRevice[];
  isLoadding: boolean;
}
const initialState: ICurrencyState = {
  listCurrencies: [],
  isLoadding: false,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    getCurrency: (state: ICurrencyState) => {
      state.isLoadding = true;
    },
    getCurrencySuccess: (state: ICurrencyState, action: PayloadAction<any>) => {
      state.isLoadding = false;
      state.listCurrencies = [...action.payload.listCurrencies];
    },
  },
});

// Action creators are generated for each case reducer function
export const currencyActions = currencySlice.actions;

export default currencySlice.reducer;
