import AppCms from '@networking/clients/AppCms';
import listUrl from '@networking/url/listUrl';

export default {
  getSiteConfig: () => {
    return AppCms.get<any>({
      url: listUrl.SITE_CONFIG.site_config,
    });
  },
};
