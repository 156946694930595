import { all, call, put, takeLatest } from 'redux-saga/effects';
import { TBaseServerDataResponse } from '@networking/types';
import { CATALOG_ACTION_TYPES } from 'redux/slices/catalog/type';
import { IElementConfig } from '@networking/service/asset/types';
import assetService from '@networking/service/asset/assetService';
import { catalogActions } from 'redux/slices/catalog';
import bannerService from '@networking/service/banner/bannerService';
import { ItemBanner } from '@networking/service/banner/types';
import gameService from '@networking/service/game/gameService';
import { ISiteConfig } from '@networking/service/siteConfig/types';
import siteConfigService from '@networking/service/siteConfig/siteConfigService';

const { getCatalogSuccess } = catalogActions;

function* watchGetCatalog(): any {
  try {
    const responseGameProperties = yield call(gameService.getGameProperties);

    const response: TBaseServerDataResponse<IElementConfig> = yield call(
      assetService.getAssetElementConfig,
    );

    const responseScreenShotVideo: TBaseServerDataResponse<ItemBanner[]> = yield call(
      bannerService.getListScreenShotVideo,
    );

    // console.log(responseScreenShotVideo, 'responseScreenShotVideo');

    const newScreenShotVideo = responseScreenShotVideo.data?.reduce((acc, obj): any => {
      const tmp = obj.urls.map(item => {
        return {
          gameId: obj.gameId,
          url: item,
          type: 'IMG',
        };
      });
      const tmpVideo = (obj?.videoUrls || []).map(item => {
        return {
          gameId: obj.gameId,
          url: item,
          type: 'VIDEO',
        };
      });
      const item = [...acc, ...tmp, ...tmpVideo];
      return item;
    }, []);
    const responseBanner: TBaseServerDataResponse<ItemBanner[]> = yield call(
      bannerService.getListBanner,
    );
    const newBanner = responseBanner.data?.reduce((acc, obj): any => {
      const tmp = obj.urls.map(item => {
        return {
          gameId: obj.gameId,
          url: item,
          deviceTypes: obj.deviceTypes,
        };
      });
      const item = [...acc, ...tmp];
      return item;
    }, []);

    const responseSiteConfig: TBaseServerDataResponse<ISiteConfig> = yield call(
      siteConfigService.getSiteConfig,
    );

    const responseForbiddenWords: TBaseServerDataResponse<string[]> = yield call(
      gameService.getForbiddenWords,
    );
    if (
      responseGameProperties &&
      response &&
      responseScreenShotVideo &&
      responseBanner &&
      responseSiteConfig &&
      responseForbiddenWords
    ) {
      yield put(
        getCatalogSuccess({
          elementConfig: response.data,
          isLoadding: false,
          listScreenshotVideoGame: newScreenShotVideo,
          listBanner: newBanner,
          listGameProperties: responseGameProperties.data,
          listSiteConfig: responseSiteConfig.data,
          listForbiddenWords: responseForbiddenWords.data,
        }),
      );
    }

    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(CATALOG_ACTION_TYPES.GET_CATALOG, watchGetCatalog)]);
}

export default fetchWatcher;
