import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INavBarState {
  suggestions: any[];
  bookmarks: any[];
  query: string;
}

const initialState: INavBarState = {
  suggestions: [],
  bookmarks: [],
  query: '',
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    getBookmarks: (state: INavBarState, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    updateBookmarked: (state: INavBarState, action: PayloadAction<any[]>) => {
      state.suggestions = action.payload;
      state.bookmarks = action.payload;
    },
    handleSearchQuery: (state: INavBarState, action: PayloadAction<any>) => {
      let objectToUpdate;

      state.suggestions.find(item => {
        if (item.id === action.payload) {
          item.isBookmarked = !item.isBookmarked;
          objectToUpdate = item;
        }
      });

      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload);

      if (bookmarkIndex === -1) {
        state.bookmarks = [...state.bookmarks, objectToUpdate];
      } else {
        state.bookmarks.splice(bookmarkIndex, 1);
      }
    },
  },
});

export const navbarActions = navbarSlice.actions;

export default navbarSlice.reducer;
