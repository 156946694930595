import { IItemFilterSelect } from '@networking/service/game/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFilterState {
  listFilter: IItemFilterSelect[];
}

const initialState: IFilterState = {
  listFilter: [],
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setItemFilter: (state: IFilterState, action: PayloadAction<any>) => {
      state.listFilter = action.payload;
    },
  },
});

export const filterActions = filterSlice.actions;

export default filterSlice.reducer;
