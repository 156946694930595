const constants = {
  API_CONFIG: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_CMS_URL: process.env.REACT_APP_BASE_URL_CMS,
    BASE_AUTH: process.env.REACT_APP_BASE_AUTH,
    BASE_GATE_WAY: process.env.REACT_APP_BASE_GATEWAY,
    BASE_WALLET: process.env.REACT_APP_BASE_WALLET,
    BASE_LOYALTY: process.env.REACT_APP_BASE_LOYALTY,
    BASE_NOTIFICATION: process.env.REACT_APP_BASE_NOTIFICATION,
  },

  ERROR_MESSAGE: {
    CLIENT_ERROR: 'Bad request!',
    SERVER_ERROR: 'Server error!',
    TIMEOUT_ERROR: 'No response received!',
    CONNECTION_ERROR: 'Server not available, bad dns!',
    NETWORK_ERROR: 'Network not available!',
    CANCEL_ERROR: 'Request timeout!',
    UNKNOWN_ERROR: 'Unexpected Error, try again!',
  },

  APP_CONFIG: {
    BSC_RPC_URL: process.env.REACT_APP_BSC_RPC_URL,
    PRIVATE_KEY: process.env.REACT_APP_PRIVATE_KEY,
    CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    BSC_SCAN: process.env.REACT_APP_BSC_SCAN,
  },
  USER_AUTH: {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  },
  WALLET_TYPE: {
    METAMASK: 1,
  },
  SESSION_STORAGE_KEY: {
    REFRESH_TOKEN: 'refreshToken',
    TYPE_HEROS: 'typeHeros',
    FILTER_CONFIG: 'filterConfig',
    LIST_GAME: 'listGame',
    JWT: 'JWT',
    RT: 'RT',
    JWT_EXPIRE: 'JWT_EXPIRE',
    RT_EXPIRE: 'RT_EXPIRE',
    UI: 'UI',
    SK: 'SK',
    BALANCE: 'BALANCE',
    WL: 'WL',
    BALANCE_LOYALTY: 'BALANCE_LOYALTY',
    CURRENT_VIP: 'CURRENT_VIP',
  },

  FIREBASE_CONFIG: {
    API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    MSG_SENDER_ID: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
    FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID,
  },

  MARKET_CONFIG: {
    EXCHANGE: 1,
    RENTAL: 2,
    MY_ITEM: 3,
    MY_RENTED: 4,
  },

  RENTAL_STATUS: {
    UN_LIST: 'NORENT',
    LISTING: 'WAITING',
    RENTED: 'RENTED',
  },

  STATUS: {
    SUCCESS: 0,
  },

  AUTH_TYPE: {
    CREDENTIALS: 'CREDENTIALS',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    WALLET: 'WALLET',
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
  },

  PAGINATOR: {
    PAGE_NO: 1,
    PAGE_SIZE: 10,
  },

  ELEMENT_CLASS: {
    CLASS: 'CLASS',
    ELEMENT: 'ELEMENT',
    ELEMENT_BACKGROUND_COLOR: 'ELEMENT_BACKGROUND_COLOR',
    RACE: 'RACE',
    RARITY: 'RARITY',
    ATTACK_TYPE: 'ATTACK_TYPE',
    FUEL_TYPE: 'FUEL_TYPE',
  },
  STATUS_NFT: {
    NONE: 'Inactive',
    SALE: 'Listing',
    WAIT_SALE_LISTING: 'UnListing',
    ACTIVE_IN_GAME: 'Active In Game',
  },
  MAPPING_CLASS_ELEMENT: {
    CLASS: 'Class',
    ELEMENT: 'Element',
    RACE: 'Race',
    RARITY: 'Rarity',
    ATTACK_TYPE: 'Attack Type',
  },
  ROUTER_ASSET_MGT: [
    '/asset-mgt/my-collections',
    '/wallet/fiat/swap',
    '/wallet/crypto/swap',
    '/asset-mgt/swap',
    '/asset-mgt/withdraw',
    '/asset-mgt/sale',
  ],
  SORT: {
    'updatedAt:desc': 'Least recently update',
    'updatedAt:asc': 'Recently update',
    'salePrice:desc': 'Price: High to Low',
    'salePrice:asc': 'Price: Low to High',
  },
  TYPE_VIEW_MODE: {
    GRID: 'grid',
    LIST: 'list',
  },
  PARAMS_KEY: {
    CLIENT_ID: 'clientId',
    CLIENT_SECRET: 'clientSecret',
    NOTIFICATION_DEVICE_TYPE: 'notificationDeviceType',
    FIREBASE_TOKEN: 'firebaseToken',
    EMAIL: 'email',
    TOKEN: 'token',
    INTRODUCER: 'introducer',
  },
  DOCS_LINK: 'https://documents.metafi.sh/introduction/what-is-metafi',
};
export default constants;
