import GateWayClient from '@networking/clients/GateWayClient';
import { TBaseApiResponse, TBaseServerDataResponse } from '@networking/types';
import listUrl from '@networking/url/listUrl';
import {
  IAssetSaleParams,
  IElementConfig,
  IFeeParams,
  IFeeResponse,
  IListNftResponse,
  INftItem,
  ITransactionResponse,
  IWithListAddParams,
  ParamsGetWithList,
  TBodySaleListing,
  TParamGetGetAssetHeroesSwap,
  TParamsSaleUnListing,
  TParamTransactionHistory,
} from './types';

export default {
  getAssetSale: (params: IAssetSaleParams) => {
    return GateWayClient.get<IListNftResponse>({
      url: listUrl.ASSET.get_asset_sale,
      params,
    });
  },

  getMyAsset: (params: IAssetSaleParams) => {
    return GateWayClient.get<IListNftResponse>({
      url: listUrl.ASSET.my_asset,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getDetailAssetSale: (id: string) => {
    return new Promise((resolve, reject) => {
      GateWayClient.get<TBaseServerDataResponse<INftItem>>({
        url: `${listUrl.ASSET.get_asset_sale}/${id}`,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saleUnListing: (data: TParamsSaleUnListing) => {
    return GateWayClient.post<any>({
      url: listUrl.ASSET.sale_unlisting,
      data,
      config: {
        isCheckAuth: true,
      },
    });
  },

  saleListing: (data: TBodySaleListing) => {
    return GateWayClient.post<any>({
      url: listUrl.ASSET.sale_listing,
      data,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getTransactionHistories: (params: TParamTransactionHistory) => {
    return GateWayClient.get<ITransactionResponse>({
      url: listUrl.ASSET.transaction_histories,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getAssetElementConfig: () => {
    return GateWayClient.get<IElementConfig>({
      url: listUrl.ASSET.element_config,
    });
  },

  getAssetHeroesSwap: (params: TParamGetGetAssetHeroesSwap) => {
    return GateWayClient.get<any>({
      url: listUrl.ASSET.get_asset_heroes_by_game,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  addItemsWithList: (data: IWithListAddParams) => {
    return GateWayClient.post<TBaseServerDataResponse<any>>({
      url: listUrl.ASSET.with_list,
      data,
      config: {
        isCheckAuth: true,
        isEncrypt: true,
      },
    });
  },

  getItemsWithList: () => {
    return GateWayClient.get<any>({
      url: `${listUrl.ASSET.with_list}/item`,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getListWithList: (params: ParamsGetWithList) => {
    return GateWayClient.get<IListNftResponse>({
      url: `${listUrl.ASSET.with_list}`,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  deleteWishlistItem: (itemId: number) => {
    return GateWayClient.delete<any>({
      url: `${listUrl.ASSET.with_list}/${itemId}`,
      config: {
        isCheckAuth: true,
      },
    });
  },

  getFee: (params: IFeeParams) => {
    return GateWayClient.get<IFeeResponse>({
      url: listUrl.ASSET.fee_market,
      params,
    });
  },
};
