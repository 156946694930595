import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'utility/context';
import LoaddingContainer from '@core/components/spinner/Loadding';

interface IProps {
  children: React.ReactNode;
}

export function LoadingProvider(props: IProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const show = () => {
    document.body.style.overflow = 'hidden';
    setLoading(true);
  };

  const hide = () => {
    document.body.style.overflow = 'visible';
    setLoading(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading: loading,
        show: () => show(),
        hide: () => hide(),
      }}>
      <>
        {loading && <LoaddingContainer />}
        {props.children}
      </>
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node,
};
