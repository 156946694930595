import { ParamsGetAssetSale } from '@core/models/market/asset.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICollectionsState {
  isCollectionLoadding: boolean;
  collectionsNFT: any;
}
const initialState: ICollectionsState = {
  isCollectionLoadding: false,
  collectionsNFT: null,
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    getCollectionsNFT: (
      state: ICollectionsState,
      action: PayloadAction<ParamsGetAssetSale>,
    ) => {
      state.isCollectionLoadding = true;
    },
    getCollectionsNFTSuccess: (
      state: ICollectionsState,
      action: PayloadAction<ICollectionsState>,
    ) => {
      state.isCollectionLoadding = false;
      state.collectionsNFT = { ...action.payload.collectionsNFT };
    },
  },
});

// Action creators are generated for each case reducer function
export const collectionsActions = collectionsSlice.actions;

export default collectionsSlice.reducer;
