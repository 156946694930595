import {
  ApiRequestParams,
  CancelRequestParams,
  GetRequestParams,
  PostRequestParams,
  TApiErrorResponse,
  TBaseApiResponse,
  TBaseServerDataResponse,
} from '../types';
import { ApisauceInstance, create, CancelToken, ApiResponse } from 'apisauce';
import axios, { CancelTokenSource } from 'axios';
import { logRequestInterceptor, logResponseMonitor } from '../extension/apiMonitors';
import { transformError, transformRequest } from '../extension/apiTranforms';
import getRequestUrl from '../url';
import { LoginDataResponse } from '../service/auth/types';
import { v4 as uuidv4 } from 'uuid';

const REQUEST_TIMEOUT = 20000;

const apiInstance = (baseUrl = '') => {
  const instance = create({
    baseURL: baseUrl,
  });

  instance.axiosInstance.interceptors.request.use(logRequestInterceptor);

  return instance;
};

class BaseApiClient {
  instance: ApisauceInstance;
  cancelToken = axios.CancelToken;
  requestInstance: {
    [key: string | number]: CancelTokenSource;
  } = {};
  timeExpired = 0;
  refreshToken: string | undefined = '';
  refreshExpiresIn: number | undefined = 0;
  sk = '';
  isCheckAuth = false;
  isEncrypt = false;
  constructor(baseUrl: string | undefined) {
    this.instance = apiInstance(baseUrl);
    this.instance.addMonitor(logResponseMonitor);
    this.instance.addRequestTransform(transformRequest);
    this.instance.addResponseTransform(transformError);
  }

  setAuthorization = (tokenData: LoginDataResponse) => {
    const { accessToken, expiresIn, refreshToken, refreshExpiresIn, sk } = tokenData;
    this.instance.setHeader('Authorization', `Bearer ${accessToken}`);
    this.timeExpired = new Date().getTime() + expiresIn;
    this.refreshToken = refreshToken;
    this.refreshExpiresIn = refreshExpiresIn;
    this.sk = sk;
  };

  clearAuthorization = () => {
    this.instance.setHeader('Authorization', `null`);
    this.timeExpired = 0;
    this.refreshToken = '';
    this.refreshExpiresIn = 0;
    this.sk = '';
  };

  initTokenCancel = (requestId: string): CancelTokenSource => {
    const source = CancelToken.source();
    this.requestInstance[requestId] = source;
    return source;
  };

  cancelRequest = ({ requestId, url, isManual }: CancelRequestParams) => {
    console.log(`cancelRequest from ${url}-${requestId}`);
    if (this.requestInstance[requestId]) {
      const cancelMessage = isManual ? 'manual' : '';
      this.requestInstance[requestId].cancel(cancelMessage);
      delete this.requestInstance[requestId];
    }
  };

  fetchApi = <TData = any>({
    method,
    config,
  }: ApiRequestParams): TBaseApiResponse<TData> => {
    const {
      url,
      suffixUrl,
      isKeepRequest = false,
      isCheckAuth = this.isCheckAuth,
      customHeaders = {},
      isEncrypt = this.isEncrypt,
      requestId = uuidv4().toString(),
      isHandleError = true,
    } = config;

    let requestUrl = getRequestUrl(url!);
    if (suffixUrl) {
      requestUrl = `${requestUrl}/${suffixUrl}`;
    }
    const cancelTimeout = isKeepRequest
      ? null
      : setTimeout(() => {
          this.cancelRequest({ requestId, url, isManual: false });
        }, REQUEST_TIMEOUT);

    const sourceCancel = this.initTokenCancel(requestId);

    return new Promise((resolve, reject) => {
      this.instance
        .any<TBaseServerDataResponse<TData>>({
          method,
          url: requestUrl,
          headers: {
            'X-Request-ID': requestId,
            isCheckAuth,
            isEncrypt,
            ...customHeaders,
          },
          cancelToken: sourceCancel.token,
          ...config,
        })
        .then(response => {
          if (response.ok && response.data) {
            resolve(response.data);
          } else {
            // this.handleError(response);
            // reject(response);
            const error = this.handleError(response, isHandleError);
            reject(error);
          }
        })
        .finally(() => {
          if (cancelTimeout) {
            clearTimeout(cancelTimeout);
          }
        });
    });
  };

  handleError = (
    error: ApiResponse<TBaseServerDataResponse<any>>,
    isShowError: boolean,
  ): TApiErrorResponse<any> => {
    let errorMessage = '';
    if (error.data?.message) {
      errorMessage = error.data?.message;
    }
    return {
      ...error,
      message: errorMessage,
    };
  };

  get = <TData>({
    url,
    params,
    config,
    data,
  }: GetRequestParams): TBaseApiResponse<TData> => {
    return this.fetchApi({
      method: 'GET',
      config: {
        ...(config || {}),
        url,
        params,
        data,
      },
    });
  };

  post = <TData>({
    url,
    data,
    config,
    params,
  }: PostRequestParams): TBaseApiResponse<TData> => {
    return this.fetchApi({
      method: 'POST',
      config: {
        ...(config || {}),
        url,
        data,
        params,
      },
    });
  };

  put = <TData>({
    url,
    data,
    config,
    params,
  }: PostRequestParams): TBaseApiResponse<TData> => {
    return this.fetchApi({
      method: 'PUT',
      config: {
        ...(config || {}),
        url,
        data,
        params,
      },
    });
  };

  delete = <TData>({
    url,
    params,
    config,
  }: GetRequestParams): TBaseApiResponse<TData> => {
    return this.fetchApi({
      method: 'DELETE',
      config: {
        ...(config || {}),
        url,
        params,
      },
    });
  };
}

export default BaseApiClient;
