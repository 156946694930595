import { all, call, put, takeLatest } from 'redux-saga/effects';
import topUpService from '@networking/service/topUp/topUpService';
import { PayloadAction } from '@reduxjs/toolkit';
import { TBaseServerDataResponse } from '@networking/types';
import { characterActions } from 'redux/slices/character';
import { IGameCharactersRevice } from '@networking/service/topUp/types';
import { CHARACTER_ACTION } from 'redux/slices/character/types';

const { getGameCharacterSuccess } = characterActions;

function* watchGetGameCharacter(action: PayloadAction<any>): any {
  try {
    const params = {
      ...action.payload,
    };
    const response: TBaseServerDataResponse<IGameCharactersRevice> = yield call(
      topUpService.getGameCharacters,
      params,
    );
    yield put(
      getGameCharacterSuccess({
        gameCharacter: response.data,
        isLoaddingCharacter: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(CHARACTER_ACTION.GET_CHARACTER, watchGetGameCharacter)]);
}

export default fetchWatcher;
