import { ParamsGetAssetSale } from '@core/models/market/asset.type';
import { IListNftResponse } from '@networking/service/asset/types';
import { IGameReceiver } from '@networking/service/game/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMarketState {
  dataNFT: IListNftResponse;
  isLoadding: boolean;
  listGame?: any;
}
const initialState: IMarketState = {
  dataNFT: { data: [], pageNo: 0, pageSize: 20, totalCount: 0, totalPage: 0 },
  isLoadding: false,
  listGame: [],
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    getListNFT: (state: IMarketState, action: PayloadAction<ParamsGetAssetSale>) => {
      state.isLoadding = true;
    },
    getListNFTSuccess: (state: IMarketState, action: PayloadAction<IMarketState>) => {
      state.isLoadding = false;
      state.dataNFT = { ...action.payload.dataNFT };
    },
  },
});

// Action creators are generated for each case reducer function
export const marketActions = marketSlice.actions;

export default marketSlice.reducer;
