import { all, call, put, takeLatest } from 'redux-saga/effects';
import { TBaseServerDataResponse } from '@networking/types';
import { ICurrencyRevice } from '@networking/service/currency/types';
import currencyService from '@networking/service/currency/currencyService';
import { currencyActions } from 'redux/slices/currency';
import { CURRENCY_ACTION_TYPES } from 'redux/slices/currency/type';

const { getCurrencySuccess } = currencyActions;

function* watchGetCurrency(): any {
  try {
    const responseCurrencies: TBaseServerDataResponse<ICurrencyRevice[]> = yield call(
      currencyService.getCurrencies,
    );

    const currencies = responseCurrencies.data?.filter(f => !f.isFiat);

    yield put(
      getCurrencySuccess({
        isLoadding: false,
        listCurrencies: currencies,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(CURRENCY_ACTION_TYPES.GET_CATALOG, watchGetCurrency)]);
}

export default fetchWatcher;
