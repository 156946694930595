import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageType } from './types';

interface ILocaleState {
  language: LanguageType;
  languageDisplay: {
    [key in LanguageType]: string;
  };
}

const initialState: ILocaleState = {
  language: 'en',
  languageDisplay: {
    en: 'English',
    de: 'German',
    fr: 'French',
    pt: 'Portuguese',
  },
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLanguage: (state: ILocaleState, action: PayloadAction<LanguageType>) => {
      state.language = action.payload;
    },
  },
});

export const localeActions = localeSlice.actions;

export default localeSlice.reducer;
