import { useState } from 'react';

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => {
    setIsVisible(true);
  };

  const toggle = () => {
    setIsVisible(prev => !prev);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  return {
    isVisible,
    showModal,
    hideModal,
    toggle,
  };
};

export default useModal;
