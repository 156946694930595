import { ParamsGetWithList } from '@networking/service/asset/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IWithListState {
  isLoadding: boolean;
  listWithList: any;
  myWithList?: any;
}
const initialState: IWithListState = {
  isLoadding: false,
  listWithList: null,
  myWithList: [],
};

export const withListSlice = createSlice({
  name: 'withList',
  initialState,
  reducers: {
    getWithList: (state: IWithListState, action: PayloadAction<ParamsGetWithList>) => {
      state.isLoadding = true;
    },
    getWithListSuccess: (
      state: IWithListState,
      action: PayloadAction<IWithListState>,
    ) => {
      state.isLoadding = false;
      state.listWithList = { ...action.payload.listWithList };
    },
    getMyWithList: (state: IWithListState) => {
      state.isLoadding = true;
    },
    getMyWithListSuccess: (state: IWithListState, action: PayloadAction<any>) => {
      state.isLoadding = false;
      state.myWithList = action.payload.listWithList;
    },
  },
});

// Action creators are generated for each case reducer function
export const withListActions = withListSlice.actions;

export default withListSlice.reducer;
