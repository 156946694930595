import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { balancesActions } from 'redux/slices/balances';
import balanceService from '@networking/service/balance/balanceService';
import { BALANCES_ACTION } from 'redux/slices/balances/types';
import notificationService from '@networking/service/notification/notificationService';

const { getBalancesSuccess } = balancesActions;

function* watchGetBalances(action: PayloadAction<any>): any {
  try {
    const balancesData = yield call(balanceService.getBalance);
    const balancesLoyalty = yield call(balanceService.getBalanceLoyalty);
    const currentVip = yield call(balanceService.getCurrentVip);
    const refQuest = yield call(balanceService.getRefQuest);
    // const notification = yield call(notificationService.getNotifications);
    yield put(
      getBalancesSuccess({
        balances: balancesData.data,
        balanceLoyalty: balancesLoyalty.data,
        currentVip: currentVip.data,
        refQuest: refQuest.data,
        // notification: notification.data,
        isLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([takeLatest(BALANCES_ACTION.GET_BALANCES, watchGetBalances)]);
}

export default fetchWatcher;
