import { IElementConfig } from '@networking/service/asset/types';
import { ItemBanner } from '@networking/service/banner/types';
import { ISiteConfig } from '@networking/service/siteConfig/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAssetState {
  elementConfig: IElementConfig[];
  listBanner: ItemBanner[];
  listScreenshotVideoGame: ItemBanner[];
  listGameProperties: any;
  listSiteConfig: ISiteConfig;
  isLoadding: boolean;
  listForbiddenWords: string[];
}
const initialState: IAssetState = {
  elementConfig: [],
  listBanner: [],
  listScreenshotVideoGame: [],
  listGameProperties: [],
  isLoadding: false,
  listSiteConfig: {},
  listForbiddenWords: [],
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    getCatalog: (state: IAssetState) => {
      state.isLoadding = true;
    },
    getCatalogSuccess: (state: IAssetState, action: PayloadAction<any>): any => {
      state.isLoadding = false;
      state.elementConfig = [...action.payload.elementConfig];
      state.listBanner = [...action.payload.listBanner];
      state.listScreenshotVideoGame = [...action.payload.listScreenshotVideoGame];
      state.listGameProperties = [...action.payload.listGameProperties];
      state.listSiteConfig = action.payload.listSiteConfig;
      state.listForbiddenWords = action.payload.listForbiddenWords;
    },
  },
});

// Action creators are generated for each case reducer function
export const catalogActions = catalogSlice.actions;

export default catalogSlice.reducer;
