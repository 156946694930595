import { TBaseApiResponse } from '@networking/types';
import { ApiResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';

export const logRequestInterceptor = (config: AxiosRequestConfig) => {
  // try {
  //   console.log('---------Start Call Api--------');
  //   // console.log('REQUEST URL = ', `${config.baseURL}${config.url}`);
  //   // console.log('REQUEST HEADERS = ', JSON.stringify(config.headers));
  //   console.log('REQUEST PARAMS = ', JSON.stringify(config.params));
  //   console.log('REQUEST DATA = ', JSON.stringify(config.data));
  // } catch (error) {
  //   console.log(error, 'client.interceptors.request.use');
  // }
  return config;
};

export const logResponseMonitor = (response: ApiResponse<TBaseApiResponse<any>>) => {
  // if (!response.ok) {
  //   console.log('RESPONSE PROBLEM = ', response.problem);
  //   console.log('RESPONSE ERROR = ', response.originalError);
  // } else {
  //   console.log('RESPONSE DATA = ', response.data);
  // }
  // console.log('---------End Call API----------');
};
