import NotificationClient from '@networking/clients/NotificationClient';
import listUrl from '@networking/url/listUrl';
import { INotificationParams } from './types';

export default {
  getNotifications: (params: INotificationParams) => {
    return NotificationClient.get({
      url: listUrl.NOTIFICATION.notification,
      params,
      config: {
        isCheckAuth: true,
      },
    });
  },

  countUnread: () => {
    return NotificationClient.get({
      url: listUrl.NOTIFICATION.count_unread,
      config: {
        isCheckAuth: true,
      },
    });
  },

  markAsRead: (notificationId: number) => {
    return NotificationClient.put({
      url: `${listUrl.NOTIFICATION.notification}/${notificationId}/mark-as-read`,
      config: {
        isCheckAuth: true,
      },
    });
  },

  markAsReadAll: () => {
    return NotificationClient.put({
      url: listUrl.NOTIFICATION.read_all,
      config: {
        isCheckAuth: true,
      },
    });
  },
};
