import { ParamsGetAssetSale } from '@core/models/market/asset.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGameCharacterState {
  isLoaddingCharacter: boolean;
  gameCharacter: any;
}
const initialState: IGameCharacterState = {
  isLoaddingCharacter: false,
  gameCharacter: null,
};

export const characterSlice = createSlice({
  name: 'character',
  initialState,
  reducers: {
    getGameCharacter: (
      state: IGameCharacterState,
      action: PayloadAction<ParamsGetAssetSale>,
    ) => {
      state.isLoaddingCharacter = true;
    },
    getGameCharacterSuccess: (
      state: IGameCharacterState,
      action: PayloadAction<IGameCharacterState>,
    ) => {
      state.isLoaddingCharacter = false;
      state.gameCharacter = { ...action.payload.gameCharacter };
    },
  },
});

// Action creators are generated for each case reducer function
export const characterActions = characterSlice.actions;

export default characterSlice.reducer;
