import { all, call, put, takeLatest } from 'redux-saga/effects';
import assetService from '@networking/service/asset/assetService';
import { PayloadAction } from '@reduxjs/toolkit';
import { IListNftResponse } from '@networking/service/asset/types';
import { TBaseServerDataResponse } from '@networking/types';
import { WITHLIST_ACTION } from 'redux/slices/withList/types';
import { withListActions } from 'redux/slices/withList';

const { getWithListSuccess, getMyWithListSuccess } = withListActions;

function* watchGetWithList(action: PayloadAction<any>): any {
  try {
    const params = {
      ...action.payload,
    };
    const response: TBaseServerDataResponse<IListNftResponse> = yield call(
      assetService.getListWithList,
      params,
    );
    yield put(
      getWithListSuccess({
        listWithList: response.data,
        isLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* watchGetMyWithList(action: PayloadAction<any>): any {
  try {
    const response = yield call(assetService.getItemsWithList);
    yield put(
      getMyWithListSuccess({
        listWithList: response.data,
        isLoadding: false,
      }),
    );
    return;
  } catch (error) {
    console.log(error, 'error');
  }
}

function* fetchWatcher() {
  yield all([
    takeLatest(WITHLIST_ACTION.GET_WITHLIST, watchGetWithList),
    takeLatest(WITHLIST_ACTION.GET_MY_WITHLIST, watchGetMyWithList),
  ]);
}

export default fetchWatcher;
