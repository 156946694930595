import SecureHelper from '@core/helpers/SecureHelper';
import constants from 'utility/constants';
import BaseApiClient from './BaseApiClient';

class LoyaltyClient extends BaseApiClient {
  constructor() {
    super(constants.API_CONFIG.BASE_LOYALTY);
    this.isCheckAuth = true;
    this.isEncrypt = false;
    SecureHelper.setPrivateKey(constants.APP_CONFIG.PRIVATE_KEY || 'alpha2202');
  }
}

export default new LoyaltyClient();
